import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Fuse from "fuse.js";
import axios from "axios";
import { API_URL } from "../constants";
import BookingCard from "./BookingCard";

const BookingsList = ({ bookingsRefresh }) => {
   const { user } = useSelector((state) => state.auth);
   const [isFetching, setIsFetching] = useState(false);
   const [error, setError] = useState({
      message: "Server Error Occured. Try again",
      hasError: false,
   });
   const [initialBookings, setInitialBookings] = useState([]);
   const [bookings, setBookings] = useState([]);
   const [search, setSearch] = useState("");

   const fetchBookings = useCallback(async () => {
      console.log("Fetching Bookings");
      try {
         setIsFetching(true);
         const res = await axios.get(`${API_URL}/api/orders`, {
            params: {
               today: true,
               area: user.alias,
            },
         });
         console.log({ bookings });
         setBookings(res.data.orders);
         setInitialBookings(res.data.orders);
         setError({
            message: "",
            hasError: false,
         });
         setIsFetching(false);
      } catch (error) {
         console.log({ error });
         setIsFetching(false);
         setError({
            message: error.response
               ? error.response.message
               : "Server Error Occured. Try Again",
            hasError: true,
         });
      }
   }, []);

   useEffect(() => {
      console.log("fetching bookings called");
      fetchBookings();
   }, [fetchBookings, bookingsRefresh]);

   const refreshBookings = () => {
      console.log("refreshing bookings");
      fetchBookings();
   };

   const submitSearch = () => {
      const fuse = new Fuse(initialBookings, {
         keys: ["shortId", "user.firstname", "user.phonenumber", "keyNumber"],
      });

      if (!search) {
         setBookings(initialBookings);
         return;
      }

      const result = fuse.search(search);
      const filteredBookings = result.map((x) => x.item);
      setBookings(filteredBookings);
   };

   const handleBookingActions = async (type, orderId) => {
      let payload = {};
      if (type === "MARK_COMPLETED") {
         payload.isMarkedAs = "COMPLETED";
      } else if (type === "MARK_CANCELLED") {
         payload.isMarkedAs = "CANCELLED";
      } else if (type === "PAYMENT_DONE") {
         payload.paymentStatus = true;
      } else if (type === "NOT_PAID") {
         payload.paymentStatus = false;
      } else if (type === "EXEMPTED") {
         payload.paymentType = "EXEMPTED";
         payload.paymentStatus = true;
      } else if (type === "PAID_CASH") {
         payload.paymentType = "OFFLINE";
         payload.paymentStatus = true;
      }
      //  else if (type === "MARK_CANCELLED") {
      //   payload.isMarkedAs = "CANCELLED";
      // }
      //  else if (type === "EXEMPTED") {
      //   payload.paymentType = "EXEMPTED";
      //   payload.paymentStatus = true;
      // }

      const { data } = await axios.put(
         `${API_URL}/api/orders/${orderId}`,
         payload
      );

      if (data.success) {
         refreshBookings();
      }
   };

   // confirmation before taking booking actions like PaymentDone/ Mark Completed/ Mark Exempted/ Mark Cancelled confirmBookingActions function will confirm the action of the user
   const confirmBookingActions = (type, orderId) => {
      if (type === "PAYMENT_DONE") {
         if (
            window.confirm(
               "Are you sure you want to mark payment done for this booking?"
            )
         ) {
            handleBookingActions(type, orderId);
         }
      } else if (type === "MARK_COMPLETED") {
         if (
            window.confirm(
               "Are you sure you want to mark this booking completed?"
            )
         ) {
            handleBookingActions(type, orderId);
         }
      } else if (type === "EXEMPTED") {
         if (
            window.confirm(
               "Are you sure you want to mark this booking exempted?"
            )
         ) {
            handleBookingActions(type, orderId);
         }
      } else if (type === "MARK_CANCELLED") {
         if (
            window.confirm(
               "Are you sure you want to mark this booking cancelled?"
            )
         ) {
            handleBookingActions(type, orderId);
         }
      } else if (type === "PAID_CASH") {
         if (window.confirm("Please confirm if payment is done in CASH")) {
            handleBookingActions(type, orderId);
         }
      }

      return;
   };

   return (
      <div className="px-2">
         <div className="container">
            <div className="mb-3">
               <div className="d-flex align-items-center justify-content-between mb-4 pb-3">
                  <h3 className="mb-0">History</h3>
                  <div className="d-flex align-items-center">
                     <button
                        className="btn btn-outline-success me-2"
                        onClick={refreshBookings}
                     >
                        Refresh
                     </button>
                  </div>
               </div>

               <div>
                  <div className="mb-3">
                     <div className="input-group">
                        <input
                           type="text"
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                           className="form-control"
                           placeholder="Search by : Key, Name or Number"
                        />
                        <button
                           className="btn btn-sm btn-primary"
                           onClick={submitSearch}
                        >
                           Search
                        </button>
                     </div>
                  </div>
                  <div className="d-flex align-items-center">
                     <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                        Total Bookings: {bookings.length}
                     </p>
                  </div>
               </div>
            </div>
            {bookings.length > 0 && !isFetching && !error.hasError && (
               <div className="row mb-5">
                  {bookings.map((booking) => (
                     <div className="col-12 col-md-6 mb-3" key={booking._id}>
                        <BookingCard
                           booking={booking}
                           confirmBookingActions={confirmBookingActions}
                        />
                     </div>
                  ))}
               </div>
            )}

            {bookings.length < 1 && !isFetching && !error.hasError && (
               <div className="alert alert-info mb-5" role="alert">
                  <p className="mb-0 text-center">No bookings available</p>
               </div>
            )}
            {error.hasError && (
               <div className="alert alert-danger mb-5" role="alert">
                  <p className="mb-0 text-center">{error.message}</p>
               </div>
            )}

            {isFetching && (
               <div className="d-flex justify-content-center mt-4 pb-5">
                  <div className="spinner-border text-primary" role="status">
                     <span className="visually-hidden">Loading...</span>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default React.memo(BookingsList);
